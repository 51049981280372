@import "~antd/dist/antd.css";
@import "./variables.scss";

/// Responsive mobil messaging
.dtb-message-entry {
  width: 100%;
}

// messaging
.dtb-chat-detail-content {
  overflow-y: auto;
  min-height: 0;
  text-align: center;
  padding: 10px;
}
.ant-layout-footer {
  padding: 0;
  padding-top: 10px;
  background: #f0f2f5;
  border-top: 1px solid #d9d9d9;
}
/// max with 768px
@media only screen and (max-width: 768px) {
  ///
  .ant-tabs .ant-layout,
  .ant-layout .ant-tabs-tabpane {
    overflow-y: auto;
    height: 100%;
  }
  .ant-layout {
    .dtb-page-content-layout {
      display: flex;
      flex-direction: column;
      flex: 1 1 0;
      height: 100%;
      width: 100%;
      //background: red;

      .ant-table-thead,
      .ant-table-header {
        .ant-table-cell {
          height: 60px !important;
          padding: 5px;
          word-wrap: break-word;
          overflow-wrap: break-word;
          webkit-hyphens: auto;
          -ms-hyphens: auto;
          hyphens: auto;
          -webkit-hyphens: auto;
          -webkit-hyphenate-limit-chars: auto 3;
          -webkit-hyphenate-limit-lines: 4;
          -ms-hyphens: auto;
          -ms-hyphenate-limit-chars: auto 3;
          -ms-hyphenate-limit-lines: 4;
          word-break: normal;
          word-break: keep-all;
        }
        height: 60px !important;
      }

      .dtb-page-content,
      .ant-layout,
      .ant-layout-content {
        //display: flex;
        //flex-direction: column;
        //flex: 1 1 0;
        height: 100%;
        //  width: 100%;
      }
      .ant-table-container {
        display: block;
      }
    }
  }

  .dtb-header {
    .ant-menu,
    .none-mobil {
      background: none;
      .navbar-item {
        background: none;
      }
      line-height: 59px !important;
      //display: none !important;
    }
  }

  .dtb-page-content-layout {
    display: "flex";
    flex: "1 1 0";
    overflow: "hidden";
    flex-direction: "row";
    height: "100%";
  }

  .dtb-page-content-layout-nav {
    float: "left";
    //background: 'blue',
    overflow: "hidden";
    width: "200px";
    height: "100%";
  }

  .dtb-content-inner-scroll-container {
    overflow-y: auto;
    height: "100%";
    margin: "10px 10px 10px 0px";
    padding: 20;
  }

  .dtb-page-content {
    display: "flex";
    flex-direction: "column";
    //flexFlow: 'wrap',
    flex: "1 1 0";
    float: "left";
    //background: 'red',
    overflow: "hidden";
  }

  .stylee-header {
    height: 40px;
    //background: yellow;
    padding: 0px 0px 15px 0px;
  }

  // details style
  .dtb-content-wrapper-with-sidebar,
  .dtb-content,
  .content-scroll-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;

    margin: 0px;
    //background: #fff;
  }
  .content-scroll-wrapper {
    margin: 0px 0px;
  }

  .ant-tabs-content,
  .ant-tabs-content-top {
    display: contents;
  }
}
.ant-menu-horizontal {
  border-bottom: none;
}
.ant-menu-item {
  .ant-menu-item-active,
  .ant-menu-submenu-open,
  .ant-menu-submenu-active {
    color: red;
  }
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #f29663;
  border-bottom: none;
}

// page entrypoint
.dtb-page-layout-with-nav {
  display: "flex";
  width: "100%";
  overflow: "hidden";

  padding: 0px 14px 0px 14px;
  @media only screen and (min-width: 1200px) {
    padding: 0px 0px 0px 0px;
  }
}

.detail-item {
  width: 100%;
}
tr.ant-descriptions-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  .ant-descriptions-item {
    margin: 0;
    margin-bottom: 0;
  }
}
.ant-descriptions-row > th,
.ant-descriptions-row > td {
  padding-bottom: 0px;
}

.dtb-edits {
  .ant-switch,
  .ant-switch:focus,
  .ant-switch:active {
    border-color: white !important;
    box-shadow: none !important;
    outline: unset;
  }

  .ant-btn {
    border: none;
    outline: none;
    .anticon-edit {
      color: deepskyblue;
    }
    .anticon-check {
      color: green;
    }
    .anticon-close {
      color: red;
    }
  }
  .ant-btn:active {
    border: none;
    box-shadow: none;
    outline: none;
  }
}
// page patient
[ant-click-animating-without-extra-node="true"]::after {
  display: none;
}

// page patient label cut text
.ant-form-item-label {
  overflow: visible;
  white-space: nowrap;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  word-wrap: break-word;
  width: 100%;
  display: inline-block;
  //ssbackground: red;
}

.dtb-header {
  .ant-menu-light .ant-menu-item:hover,
  .ant-menu-light .ant-menu-item-active,
  .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-light .ant-menu-submenu-active,
  .ant-menu-light .ant-menu-submenu-title:hover {
    color: $primary-color;
    color: #fff !important;
    background: #333;
    a {
      color: #fff;
    }
  }
}
.ant-menu-sub {
  background: #333 !important;
  color: #fff;
  a {
    color: #fff;
  }
}

// duplicate  in vitaldata copy to fix ony direct load medication
.about-wrapper {
  .ant-row,
  .ant-col {
    .ant-col {
      padding: 3px !important;
    }
    margin: 0 !important;
    padding: 0 !important;
  }
}

.dtb-inner-content-nav {
  .ant-menu-light .ant-menu-item:hover,
  .ant-menu-light .ant-menu-item-active,
  .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-light .ant-menu-submenu-active,
  .ant-menu-light .ant-menu-submenu-title:hover {
    color: $primary-color;
    color: #fff !important;
    background: none;
    a {
      color: #000;
    }
  }
}
/*
.start-message {
  width: 100%;
  //background: yellow;
  display: flex;
  .message-item-author {
    background: deeppink;
    width: 100%;
  }
  .start-message-item {
    width: auto;
    float: right;
    display: flex;
    text-align: right;

    .start-message-bubble {
      display: flex;
      flex-flow: row wrap;
      min-width: 0;
      width: auto;
      float: right !important;
      //background: #f29663;
      width: auto;
      .message-item-author {
        width: 100%;
        display: inline-block;
        // background: green;
      }
    }
  }
}
*/
