@import './../../mixins.scss';

///
.dtb-content-wrapper-with-sidebar {
  .ant-card-head-title {
    padding: 5px 0px;
  }
  .ant-card-head,
  .ant-card-head {
    height: 10px;
    min-height: 30px;
  }

  .ant-card {
    @include white-content-wrapper;
    padding-bottom: 10px;
    .ant-card-head,
    .ant-card-body {
      padding: 0px 0px;
    }
  }
}
