@import './../abstract/mixins/grid.scss';
@import './../abstract/mixins/flexWithHeight.scss';
@import './../abstract/mixins/breakpoints.scss';

///
html,
body {
  @include grid(1fr, 1fr);
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  box-sizing: border-box;

  #root {
    overflow: hidden;
    background: none;
  }

  .dtb-main-content {
    margin: 0;
    box-sizing: border-box;
    overflow-y: auto;
    height: 100%;
    margin: 0px;
    box-sizing: border-box;
    @include breakpoint('xl', 'min') {
      .dtb-page-layout-with-nav {
        padding: 0;
        margin: 0px 10px;
      }
    }
  }

  .dtb-layout,
  .dtb-main-content,
  .ant-layout {
    background: none !important;
    width: 100%;
    max-width: 100%;
    height: 100%;
    min-height: 100%;
  }

  .dtb-layout {
    display: grid;
    grid-template-rows: 60px;
    // safari bug width
    max-width: 100%;
    max-height: 100%;
    min-height: 0;
    overflow-y: hidden;
    overflow-x: hidden;

    .dtb-header {
      @include grid-child(1, 1);
      height: 60px;
      width: 100%;
    }

    .dtb-main-content {
      @include grid-child(1, 2);
      @include flexWithHeight(column);
    }

    .dtb-main-content-scroll {
      @include grid-child(1, 2);
      @include flexWithHeight(column);
    }
  }
}
