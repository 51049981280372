@import './../abstract/mixins/breakpoints.scss';

///
.dtb-case-sidebar {
  .ant-card {
    padding: 0px 10px 10px 10px !important;
    box-shadow: 0 2px 3px 0 rgb(0 0 0 / 14%);
    margin-top: 0 !important;
    margin-bottom: 14px;
    .ant-list-item-meta {
      margin-top: 0;
    }
  }

  @media (orientation: portrait) {
    @include breakpoint('xl', 'min') {
      .dtb-case-card-treatment,
      .dtb-case-card-patient {
        display: none;
      }
    }
  }
  @media (orientation: landscape) {
    @include breakpoint('md', 'min') {
      .dtb-case-card-treatment,
      .dtb-case-card-patient {
        display: none;
      }
    }
  }

  .ant-descriptions-item-container {
    display: flex;
    width: 100%;
    overflow: hidden;
  }

  .ant-descriptions-item:first-of-type .ant-descriptions-item-container {
    display: inline-block;
  }

  .dtb-select-medplan .dtb-selected-medplan .ant-descriptions-item-content {
    width: 100% !important;
    display: inline-block;
  }

  .ant-select-selection-item {
    overflow: hidden !important;
  }
  .ant-select {
    width: auto;

    margin: 0 !important;
    display: inline-block !important;
  }

  .ant-select-selection-selected-value,
  .ant-select-selection-item {
    float: left;
    max-width: 100%;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
