///
.dtb-inner-content-nav .ant-skeleton-element {
  display: inline-block;
  width: auto;
  width: 100%;
  padding-top: 10px;
  .ant-skeleton-input {
    width: 100%;
  }
}

///
.ant-skeleton-content
  .ant-skeleton-paragraph
  > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 100% !important;
}
